import React, { useRef, lazy, Suspense, useEffect, useState } from "react";
import styled from "styled-components";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import 'tiny-slider/dist/tiny-slider.css';
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image";
import {SlArrowLeft, SlArrowRight} from "react-icons/sl"
import { Link } from "gatsby";
import PageTitle from "./page-title";
import { BackgroundOverlay } from "./TopBanner/styles";
import { CarouselItem } from "./CarouselItem";


const TinySlider = lazy(() => import("tiny-slider-react") )


const HomePageCarousel = styled(({ className, nodes, items , linkText, withOutOverlay, width, height}) => {
  const isSSR = typeof window === "undefined"
  let tslider = useRef()
  const [firstLoad, setFirstLoad] = useState(false)
  const [tinySettings, setTinySettings] = useState({
    controls:false,
    loop:true,
    preventActionWhenRunning: true,
    startIndex:1,
    responsive: {
      640: {
        items: 1
      },
      900: {
        items: 2
      }
    }
  })

  const onGoTo = (dir) => {
   tslider.slider.goTo(dir)
  }


  return (
    !isSSR && <Suspense fallback={<div />}>
      <div className={className}>
      <div className="controls">
        <div onClick={() => onGoTo('prev')} className="left">
          <SlArrowLeft size={45}  />
        </div>
        <div  onClick={() =>  onGoTo('next')} className="right">
          <SlArrowRight size={45} />
        </div>

      </div>
          <TinySlider
            ref={ts => tslider = ts}
            settings={tinySettings}
          >
            { nodes && nodes.length > 0 ?
              nodes.map(n => {
                return <CarouselItem width={width} height={height} key={n.uid}>
                  <div className="info-wrapper">
                    { !withOutOverlay && <BackgroundOverlay /> }
                    <GatsbyImage alt={n.alt ? n.alt : "js image"} image={getImage(n.image)} />
                    {n.title && <div className="info-box">
                      <div className="text">
                        <PageTitle color="white">{n.title}</PageTitle>
                        {n.value && <Link to={n.value}>{ linkText || "View more"}</Link>}
                      </div>
                    </div>}
                  </div>
                </CarouselItem>
              }) :
            <>
              <CarouselItem>
                <StaticImage src="../images/homegallery/gallery_pic_3.jpg" alt="image" />
              </CarouselItem>
              <CarouselItem>
                <StaticImage src="../images/homegallery/gallery_pic_6.jpg" alt="image" />
              </CarouselItem>
              <CarouselItem>
                <StaticImage src="../images/homegallery/gallery_pic_9.jpg" alt="image" />
              </CarouselItem>
              <CarouselItem>
                <StaticImage src="../images/homegallery/gallery_pic_3.jpg" alt="image" />
              </CarouselItem>
            </>
            }

          </TinySlider>
    </div>
    </Suspense>
  );
})`
  position: relative;

  .controls {
    div {
      position: absolute;
      z-index: 105;
      color: white;
      cursor: pointer;

      &.left {
        left: 1.5%;
        svg {
          width:30px !important;
          height:30px !important;
        }
        @media screen and (min-width: 900px) {
          left:3%;
          svg {
            width:47px !important;
            height:47px !important;
          }
        }
        top: 50%;
        transform: translateY(-50%)
      }

      &.right {
        right: 1.5%;
        svg {
          width:30px !important;
          height:30px !important;
        }
        @media screen and (min-width: 900px) {
          right:3%;
          svg {
            width:47px !important;
            height:47px !important;
          }
        }
        top: 50%;
        transform: translateY(-50%)
      }
    }
  }

  .tns-nav {
    display: none;
  }

  .tns-slider {
    display: flex;
    justify-content: space-around;
    gap: 10px;
  }

  .tns-inner {
    width: 100%;
    @media screen and (min-width:768px) {
      width:80%;
    }
    margin: auto !important;
  }

  .tns-item {
    position: relative;

    .info-wrapper {
      height: 100%;
      position: relative;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-direction: column;
      z-index: 100;
      .info-box {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        transition: all .3s ease-in;
        z-index:115;
        padding-bottom:25px;
        .text {
          display: none;
        }
        a {
          color:white;
          text-transform: uppercase;
        }
      }
    }

    &:before {
      display: block;
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      background: rgba(0, 0, 0, 0.45);
      z-index: 100;
    }
  }

  .tns-slide-active {
      .info-wrapper {
        .info-box {
          .text {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap:15px;
          }
        }
      }
    &:before {
      background: transparent;
    }
  }
`;

export default HomePageCarousel;
