import React from "react";
import styled from "styled-components"

const  GalleryList = styled(({className, children}) => {
  return (
    <div className={className}>
      {children}
    </div>
  );
})`
  width:100%;
  display: flex;
  justify-content: center;
  flex-wrap:wrap;
  align-items: center;
  //gap:1.111111%;
  gap:16px;
`;

export default  GalleryList
