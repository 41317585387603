import React, { lazy, Suspense, memo } from "react";
import styled from "styled-components";
import uniqid from "uniqid";
import { sortModulesAndAssets } from "../../../utils/helpers"

const GalleryItem = lazy(() => import("./GalleryItem") )


const Gallery = styled(memo(({ className, images, nodes, type, animate }) => {
  const isSSR = typeof window === "undefined"
  const galleryImages = images || nodes
  let itemsLength = galleryImages.length
  let rowCount = Math.ceil(itemsLength/3)
  let itemOffset = 3
  let offsetMultiplayer = 1
  let swapFlag = false
  let resultArray = []

  let sortedImages = sortModulesAndAssets(galleryImages)

  for(let i = 0; i < rowCount ; i++, offsetMultiplayer++, swapFlag = !swapFlag) {
    const tempArr = sortedImages.slice(i*itemOffset, itemOffset*offsetMultiplayer)
    const slicedArray = tempArr.map((item, index) => {
      if(!swapFlag) {
        if(index===0){
          return !isSSR && <Suspense key={item.uid} fallback={<div />}><GalleryItem count={index} animate={animate} projectUrl={item.value ? item.value : null} type={type} title={item.title ? item.title : ""} image={item && item.image ? item.image  : item} key={uniqid()} size="small" /></Suspense>
        }
        if(index===1) {
          return !isSSR && <Suspense key={item.uid} fallback={<div />}><GalleryItem count={index} animate={animate} projectUrl={item.value ? item.value : null}  type={type} title={item.title ? item.title : ""} key={uniqid()} image={item && item.image ? item.image  : item} size="big" /></Suspense>
        }
        // if(index===2) {
        //   return <component image={item} size="full" />
        // }
      }else {
        if(index===0){
          return !isSSR && <Suspense key={item.uid} fallback={<div />}><GalleryItem count={index} animate={animate} projectUrl={item.value ? item.value : null} type={type} title={item.title ? item.title : ""} key={uniqid()} image={item && item.image ? item.image  : item} size="big" /></Suspense>
        }
        if(index===1) {
          return !isSSR && <Suspense key={item.uid} fallback={<div />}><GalleryItem count={index} animate={animate} projectUrl={item.value ? item.value : null} type={type} title={item.title ? item.title : ""} key={uniqid()} image={item && item.image ? item.image  : item} size="small" /></Suspense>
        }
      }
      if(index===2) {
        return !isSSR && <Suspense key={item.uid} fallback={<div />}><GalleryItem count={index} animate={animate} projectUrl={item.value ? item.value : null} type={type} title={item.title ? item.title : ""} key={uniqid()} image={item && item.image ? item.image  : item} size="full" /></Suspense>
      }
      return null
    })
    resultArray = [...resultArray,...slicedArray]
  }
  return resultArray
}))`
`;

export default Gallery;
