import React from "react"
import Layout from "../components/layout";
import Seo from "../components/seo";
import { graphql, Link } from "gatsby";
import { Container } from "../components/Layout/Container";
import { SectionTitle } from "../components/Layout/SectionTitle";
import { Section } from "../components/Layout/Section";
import SimpleHomePageCarousel from "../components/SimpleHomePageCarousel";
import GalleryModule from "../components/Gallery/GalleryModule";
import { pluralizeWord } from "../../utils/helpers";

const Index = ({pageContext, data}) => {
  console.log(pageContext)
  const images =data.gallery?.gallery?.galleryImages?.map(n => {
    return {
      image:  n.galleryImage?.childImageSharp,
      position: n.position
    }
  })
  const galleryTags = data.allGallery.nodes.map(n => {
    return {
      uid: n.uid,
      image:  n.imageFile?.childImageSharp,
      title:  n.tags[0].locales.find(l => l.iso === pageContext.lang).title,
      value:  n.locales.find(l => l.iso === pageContext.lang).value
    }
  })
  return (
    <Layout insidePage breadCrumbLinks={pageContext.breadCrumb}>
      <Container width="full-size">
          <Section>
              <SectionTitle>
                  {pageContext.name}
              </SectionTitle>
          </Section>
          <Section>
            <GalleryModule animate={true} type="image" images={images} />
            <div style={{
              padding:"60px 30px",
              display:"flex",
              justifyContent: "center",
              alignItems: "center"
            }}>
              <Link style={{
                textTransform: "uppercase",
                fontWeight: "400"
              }} to={pageContext.areas}>View all {pluralizeWord(pageContext.breadCrumb[pageContext.breadCrumb?.length - 1].title?.toLowerCase())} </Link>
            </div>
          </Section>
      </Container>
        <Container width="full-size">
            <Section>
                <SectionTitle style={{paddingTop: "116px"}}>
                    Other areas
                </SectionTitle>
            </Section>
          <SimpleHomePageCarousel height="576px" linkText="View more" nodes={galleryTags} />
        </Container>
    </Layout>
  )
}

export const query = graphql`
    query($projectName: String, $galleryUid: String) {
        gallery(uid: {eq: $galleryUid}) {
            additionalData {
                key
                locales {
                    iso
                    value
                }
            }
            locales {
                iso
                metaTitle
                metaDescription
            }
            gallery {
                galleryImages {
                    position
                    galleryImage {
                        childImageSharp {
                            gatsbyImageData(layout: FULL_WIDTH)
                        }
                    }
                }
            }
            tags {
                tagImage {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
                locales {
                    iso
                    title
                    description
                }
            }
        }
        allGallery(
            filter: {additionalData: {elemMatch: {key: {eq: "Project name"}, locales: {elemMatch: {value: {eq: $projectName}}}}},uid: {ne: $galleryUid}}
        ) {
            nodes {
                locales {
                    iso
                    title
                    value
                    metaDescription
                    metaKeywords
                    metaTitle
                    content
                    description
                }
                additionalData {
                    key
                    locales {
                        iso
                        value
                    }
                }
                imageFile {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
                tags {
                    uid
                    locales {
                        iso
                        title
                    }
                }
            }
        }
    }
`
export const Head = ({data, pageContext}) => {
  const { metaTitle, metaDescription} = data.gallery.locales.find(l => l.iso === pageContext.lang)
  return  <Seo title={metaTitle} description={metaDescription} />
}
export default Index
