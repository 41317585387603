import React, {useState, useEffect} from "react";
import styled from "styled-components";
import GalleryList from "./GalleryList";
import Gallery from "./Gallery"
import { spliceIntoChunks } from "../../../utils/helpers";

const GalleryModule = styled(({ className, images, chunkSize, type, animate }) => {
  const [chunks, setChunks] = useState(spliceIntoChunks(images, chunkSize || 9 ))
  const [curPage, setCurPage] = useState(1);
  const [visibleImages, setVisibleImages ] = useState(chunks.slice([0,curPage]))
  const LoadMore = () => {
    setVisibleImages(chunks.slice(0,curPage))
  }

  useEffect(() => {
    LoadMore()
  }, [curPage])
  return (
    <div className={className}>
      <GalleryList>
        {visibleImages.map((images, index) => {
          return <Gallery key={index} animate={animate} type={type} images={images} />
        })}
        {
          curPage && curPage < chunks.length &&
          <button
            className="load-more-btn"
            onClick={() => setCurPage(prev => prev + 1)}
          >
            Load more
          </button>
        }
      </GalleryList>
    </div>
  )
})`
  width:100%;  
  .load-more-btn {
    background: none;
    font-family: "Gill Sans";
    border: none;
    font-size: 16px;
    font-weight: 400;
    text-transform:uppercase;
    margin: 30px auto 50px auto;
    color: #161B1F !important;
    &:hover {
      cursor: pointer;
    }
  }
`;

export default GalleryModule;
